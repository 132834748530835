import * as React from "react"
import { useEffect } from "react"
import routes from "../routes"

const domainToReplace = "gcci.at"
//const domainToReplace = "localhost:8000";
const targetDomain = "gcci.earth"

export const DomainRedirect: React.FC = () => {
  useEffect(() => {
    const originalHref = window.location.href
    if (!originalHref.includes(domainToReplace)) {
      return // nothing to do for DomainRedirect
    }

    // replace the domains
    let newHref = originalHref.replace(domainToReplace, targetDomain)

    // some special cases where the pathname is modified
    const pathname = window.location.pathname
    if (pathname === "/") {
      newHref = newHref + "de" + routes.gemAt
    } else if (
      pathname.includes(routes.gemGlo) ||
      pathname === "/de/" ||
      pathname === "/de"
    ) {
      newHref = newHref.replace(pathname, "/de" + routes.gemAt)
    }

    // do the redirect
    window.location.href = newHref
  }, [])
  return <div />
}
