import React, { useState } from "react"
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap"
import Link from "./link"
import wegcLogo from "../assets/images/WEGC_Logo_medium.png"
import uniGrazLogo from "../assets/images/UniGraz_Logo_medium.png"
import rightLogo from "../assets/images/ClimateChangeGraz_Logo_medium.jpg"
// @ts-ignore
import styled from "styled-components"
import routes, { getLocalizedRoute, useLocation } from "../routes"
import { useIntl } from "gatsby-plugin-intl"

let StyledNavbar = styled((props: any) => (
  <Navbar
    style={{ backgroundColor: "#fddc05", padding: "0.1rem 1rem" }}
    {...props}
  />
))`
  position: sticky;
  top: 0;
  z-index: 10;
`

const Header = (props: { siteTitle?: string }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dropdownActive, setDropdownActive] = useState(null)
  const intl = useIntl()
  const location = useLocation()

  const toggle = () => setIsOpen(!isOpen)
  const clickDropdown = (dropSelector: any) => {
    if (dropdownActive === dropSelector) {
      setDropdownActive(null)
    } else {
      setDropdownActive(dropSelector)
    }
  }

  function getDeEnRoute() {
    const currentRoute = (location as any).pathname
    let switchRoute
    if (currentRoute?.startsWith("/de")) {
      switchRoute = currentRoute.slice(3)
    } else {
      switchRoute = "/de" + currentRoute
    }
    if (!switchRoute.endsWith("/")) {
      switchRoute += "/"
    }
    return switchRoute
  }

  function getDeEnButtonName() {
    if (intl.locale === "de") {
      return "DE|EN"
    } else {
      return "EN|DE"
    }
  }

  const dropdownNav = (
    <div className="dropdownNav">
      <div>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href={getLocalizedRoute(routes.gemGlo, intl)}
        >
          {intl.formatMessage({ id: "geoRegions.Global" })}
        </a>
      </div>
      <div>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href={getLocalizedRoute(routes.gemEur, intl)}
        >
          {intl.formatMessage({ id: "geoRegions.Europe" })}
        </a>
      </div>
      <div>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href={getLocalizedRoute(routes.gemAt, intl)}
        >
          {intl.formatMessage({ id: "geoRegions.Austria" })}
        </a>
      </div>
    </div>
  )
  const ewmDropdownNav = (
    <div className="dropdownNav">
      <div>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href={getLocalizedRoute(routes.ewmEur, intl)}
        >
          {intl.formatMessage({ id: "geoRegions.Europe" })}
        </a>
      </div>
      <div>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href={getLocalizedRoute(routes.ewmEur, intl)}
        >
          {intl.formatMessage({ id: "geoRegions.Austria" })}
        </a>
      </div>
    </div>
  )
  const warmingDropdownNav = (
    <div className="dropdownNav">
      <div>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href={getLocalizedRoute(routes.cwmGlo, intl)}
        >
          Global
        </a>
      </div>
    </div>
  )
  const wegcLogoLink =
    intl.locale === "de"
      ? "https://wegcenter.uni-graz.at/de/"
      : "https://wegcenter.uni-graz.at/en/"
  const uniGrazLogoLink =
    intl.locale === "de"
      ? "https://www.uni-graz.at/de/"
      : "https://www.uni-graz.at/en/"
  const climateChangeGrazLogoLink =
    intl.locale === "de"
      ? "https://climate-change.uni-graz.at/de/"
      : "https://climate-change.uni-graz.at/en/"

  return (
    <StyledNavbar light expand="md">
      <Container style={{ justifyContent: "center" }}>
        <Link
          to={wegcLogoLink}
          className="navbar-brand"
          style={{ marginRight: "0.5rem" }}
        >
          <img src={wegcLogo} alt="Logo" height="50px" />
        </Link>
        <Link
          to={uniGrazLogoLink}
          className="navbar-brand"
          style={{ marginRight: "0.5rem" }}
        >
          <img src={uniGrazLogo} alt="Logo" height="50px" />
        </Link>
        <Link to={climateChangeGrazLogoLink} className="navbar-brand">
          <img src={rightLogo} alt="Logo" height="50px" />
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse style={{ flexGrow: "0.35" }} isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href={getLocalizedRoute(routes.home, intl)}>
                GCCI Home
              </NavLink>
            </NavItem>
            <NavItem style={{ position: "relative", width: "100px" }}>
              <NavLink
                className="gemNavLink"
                style={{ cursor: "pointer" }}
                onClick={() => clickDropdown("EMISSIONS")}
              >
                {intl.formatMessage({ id: "header.emissions" })}
              </NavLink>
              {dropdownActive === "EMISSIONS" && dropdownNav}
            </NavItem>
            {/* <NavItem>
              <NavLink href={getLocalizedRoute(routes.gemGlo, intl)}>
                GEM-GLO
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={getLocalizedRoute(routes.gemEur, intl)}>
                GEM-EUR
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={getLocalizedRoute(routes.gemAt, intl)}>
                GEM-AT
              </NavLink>
            </NavItem> */}
            <NavItem style={{ position: "relative", width: "100px" }}>
              <NavLink
                onClick={() => clickDropdown("WARMING")}
                className="gemNavLink"
                style={{ cursor: "pointer" }}
              >
                {intl.formatMessage({ id: "header.warming" })}
              </NavLink>
              {dropdownActive === "WARMING" && warmingDropdownNav}
            </NavItem>
            <NavItem style={{ position: "relative", width: "100px" }}>
              <NavLink
                onClick={() => clickDropdown("EXTREMES")}
                className="gemNavLink"
                style={{ cursor: "pointer" }}
              >
                {intl.formatMessage({ id: "header.extremes" })}
              </NavLink>
              {dropdownActive === "EXTREMES" && ewmDropdownNav}
            </NavItem>
            <NavItem>
              <NavLink href={getDeEnRoute()}>{getDeEnButtonName()}</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </StyledNavbar>
  )
}

export default Header
