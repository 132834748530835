import React from "react"
import { Col, Container, Row } from "reactstrap"
import Layout from "../components/layout"
import { DomainRedirect } from "./DomainRedirect"

// Polyfill for DOMParser required by formatjs
const { DOMParser } = require("xmldom")
global.DOMParser = DOMParser

let PageTemplate = ({ title, children }: { title: string; children: any }) => (
  <Layout>
    <DomainRedirect />
    <Container className="py-4">
      <Row className="d-flex justify-content-center">
        <Col sm={10}>{children}</Col>
      </Row>
    </Container>
  </Layout>
)

export default PageTemplate
