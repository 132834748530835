/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Header from "./header"
import SubFooter from "./subFooter"
import "../assets/stylesheets/layout.scss"
import "../assets/stylesheets/main.css"
// @ts-ignore
import { ThemeProvider } from "styled-components"
// @ts-ignore
import theme from "../assets/stylesheets/theme"
import { useWindowSize } from "../utils/useWindowSize"

const Layout = ({ children }: { children: any }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  let { title, author } = data.site.siteMetadata

  const [windowWidth, windowHeight] = useWindowSize()
  const [resizeNumber, setResizeNumber] = useState(0)

  useEffect(() => {
    setResizeNumber(resizeNumber + 1)
    if (resizeNumber >= 2) window.location.reload()
  }, [windowWidth])

  return (
    <ThemeProvider theme={theme}>
      {/*<Banner title={title} phone={phone} address={address}/>*/}
      <Header siteTitle={title} />
      <main>{children}</main>
      <SubFooter title={author} />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
